/** @format */

import { IntlShape } from 'gatsby-plugin-intl';
import { createIntl, createIntlCache } from 'react-intl';
import { TLocale } from '../utils/gatsby/types';
import enBlog from './translations/blog/en.json';
import frBlog from './translations/blog/fr.json';
import itBlog from './translations/blog/it.json';
import esBlog from './translations/blog/es.json';
import enBooking from './translations/booking/en.json';
import frBooking from './translations/booking/fr.json';
import itBooking from './translations/booking/it.json';
import esBooking from './translations/booking/es.json';
import enBookingLegacy from './translations/booking_legacy/en.json';
import frBookingLegacy from './translations/booking_legacy/fr.json';
import itBookingLegacy from './translations/booking_legacy/it.json';
import esBookingLegacy from './translations/booking_legacy/es.json';
import enBookingNew from './translations/bookingNew/en.json';
import itBookingNew from './translations/bookingNew/it.json';
import esBookingNew from './translations/bookingNew/es.json';
import enCities from './translations/cities/en.json';
import frCities from './translations/cities/fr.json';
import itCities from './translations/cities/it.json';
import esCities from './translations/cities/es.json';
import enCommon from './translations/common/en.json';
import frCommon from './translations/common/fr.json';
import itCommon from './translations/common/it.json';
import esCommon from './translations/common/es.json';
import enDiscover from './translations/discover/en.json';
import frDiscover from './translations/discover/fr.json';
import itDiscover from './translations/discover/it.json';
import esDiscover from './translations/discover/es.json';
import enHelpCenter from './translations/help-center/en.json';
import frHelpCenter from './translations/help-center/fr.json';
import itHelpCenter from './translations/help-center/it.json';
import esHelpCenter from './translations/help-center/es.json';
import enHome from './translations/home/en.json';
import frHome from './translations/home/fr.json';
import itHome from './translations/home/it.json';
import esHome from './translations/home/es.json';
import enLocations from './translations/locations/en.json';
import frLocations from './translations/locations/fr.json';
import itLocations from './translations/locations/it.json';
import esLocations from './translations/locations/es.json';
import enLogin from './translations/log-in/en.json';
import frLogin from './translations/log-in/fr.json';
import itLogin from './translations/log-in/it.json';
import esLogin from './translations/log-in/es.json';
import enPdp from './translations/pdp/en.json';
import frPdp from './translations/pdp/fr.json';
import itPdp from './translations/pdp/it.json';
import esPdp from './translations/pdp/es.json';
import enPlp from './translations/plp/en.json';
import frPlp from './translations/plp/fr.json';
import itPlp from './translations/plp/it.json';
import esPlp from './translations/plp/es.json';
import enPrivacy from './translations/privacy-policy/en.json';
import frPrivacy from './translations/privacy-policy/fr.json';
import itPrivacy from './translations/privacy-policy/it.json';
import esPrivacy from './translations/privacy-policy/es.json';
import enRequestInfo from './translations/request-info/en.json';
import frRequestInfo from './translations/request-info/fr.json';
import itRequestInfo from './translations/request-info/it.json';
import esRequestInfo from './translations/request-info/es.json';
import enRequestTour from './translations/request-tour/en.json';
import frRequestTour from './translations/request-tour/fr.json';
import itRequestTour from './translations/request-tour/it.json';
import esRequestTour from './translations/request-tour/es.json';
import enSitemap from './translations/sitemap/en.json';
import frSitemap from './translations/sitemap/fr.json';
import itSitemap from './translations/sitemap/it.json';
import esSitemap from './translations/sitemap/es.json';
import enWywg from './translations/wywg/en.json';
import frWywg from './translations/wywg/fr.json';
import itWywg from './translations/wywg/it.json';
import esWywg from './translations/wywg/es.json';

type TTranslationCommon = keyof typeof enCommon;
type TTanslationHome = keyof typeof enHome;
type TTranslationWywg = keyof typeof enWywg;
type TTranslationPlp = keyof typeof enPlp;
type TTranslationPdp = keyof typeof enPdp;
type TTranslationBooking = keyof typeof enBooking;
type TTranslationHelpCenter = keyof typeof enHelpCenter;
type TTranslationDiscover = keyof typeof enDiscover;
type TTranslationCities = keyof typeof enCities;
type TTranslationBlog = keyof typeof enBlog;
type TTranslationRequestInfo = keyof typeof enRequestInfo;
type TTranslationRequestTour = keyof typeof enRequestTour;
type TTranslationLocations = keyof typeof enLocations;
type TTranslationBookingLegacy = keyof typeof enBookingLegacy;
type TTranslationPrivacyPolicy = keyof typeof enPrivacy;
type TTranslationBookingNew = keyof typeof enBookingNew;
type TTranslationSitemap = keyof typeof enSitemap;
type TTranslationLogin = keyof typeof enLogin;

export type TTranslation =
  | TTranslationCommon
  | TTanslationHome
  | TTranslationWywg
  | TTranslationPlp
  | TTranslationPdp
  | TTranslationBooking
  | TTranslationBookingNew
  | TTranslationHelpCenter
  | TTranslationDiscover
  | TTranslationCities
  | TTranslationBlog
  | TTranslationRequestInfo
  | TTranslationRequestTour
  | TTranslationLocations
  | TTranslationBookingLegacy
  | TTranslationPrivacyPolicy
  | TTranslationSitemap
  | TTranslationLogin;

export const utilityCreateIntlProvider = (locale?: TLocale) => {
  const intlENCache = createIntlCache();

  const intlEN = createIntl(
    {
      locale: 'en',
      messages: {
        ...(enBlog as Record<string, any>),
        ...(enBooking as Record<string, any>),
        ...(enBooking as Record<string, string>),
        ...(enBookingNew as Record<string, any>),
        ...(enCities as Record<string, string>),
        ...(enCommon as Record<string, any>),
        ...(enDiscover as Record<string, any>),
        ...(enHelpCenter as Record<string, any>),
        ...(enHome as Record<string, any>),
        ...(enWywg as Record<string, any>),
        ...(enPlp as Record<string, any>),
        ...(enPdp as Record<string, any>),
        ...(enRequestTour as Record<string, any>),
        ...(enRequestInfo as Record<string, any>),
        ...(enLocations as Record<string, string>),
        ...(enBookingLegacy as Record<string, any>),
        ...(enPrivacy as Record<string, any>),
        ...(enSitemap as Record<string, string>),
        ...(enLogin as Record<string, any>),
      },
    },
    intlENCache,
  ) as IntlShape;

  const intlITCache = createIntlCache();
  const intlIT = createIntl(
    {
      locale: 'it',
      messages: {
        ...(itBlog as Record<string, any>),
        ...(itBooking as Record<string, any>),
        ...(itBooking as Record<string, string>),
        ...(itBookingNew as Record<string, any>),
        ...(itCities as Record<string, string>),
        ...(itCommon as Record<string, any>),
        ...(itDiscover as Record<string, any>),
        ...(itHelpCenter as Record<string, any>),
        ...(itHome as Record<string, any>),
        ...(itWywg as Record<string, any>),
        ...(itPlp as Record<string, any>),
        ...(itPdp as Record<string, any>),
        ...(itRequestTour as Record<string, any>),
        ...(itRequestInfo as Record<string, any>),
        ...(itLocations as Record<string, string>),
        ...(itBookingLegacy as Record<string, any>),
        ...(itPrivacy as Record<string, any>),
        ...(itSitemap as Record<string, string>),
        ...(itLogin as Record<string, any>),
      },
    },
    intlITCache,
  ) as IntlShape;

  const intlFRCache = createIntlCache();
  const intlFR = createIntl(
    {
      locale: 'fr',
      messages: {
        ...(frBlog as Record<string, any>),
        ...(frBooking as Record<string, any>),
        ...(frCities as Record<string, string>),
        ...(frCommon as Record<string, any>),
        ...(frDiscover as Record<string, any>),
        ...(frHelpCenter as Record<string, any>),
        ...(frHome as Record<string, any>),
        ...(frWywg as Record<string, any>),
        ...(frPlp as Record<string, any>),
        ...(frPdp as Record<string, any>),
        ...(frRequestTour as Record<string, any>),
        ...(frRequestInfo as Record<string, any>),
        ...(frLocations as Record<string, string>),
        ...(frBookingLegacy as Record<string, any>),
        ...(frPrivacy as Record<string, any>),
        ...(frSitemap as Record<string, string>),
        ...(frLogin as Record<any, any>),
      },
    },
    intlFRCache,
  ) as IntlShape;

  const intlESCache = createIntlCache();
  const intlES = createIntl(
    {
      locale: 'es',
      messages: {
        ...(esBlog as Record<string, any>),
        ...(esBooking as Record<string, any>),
        ...(esBookingNew as Record<string, any>),
        ...(esCities as Record<string, string>),
        ...(esCommon as Record<string, any>),
        ...(esDiscover as Record<string, any>),
        ...(esHelpCenter as Record<string, any>),
        ...(esHome as Record<string, any>),
        ...(esWywg as Record<string, any>),
        ...(esPlp as Record<string, any>),
        ...(esPdp as Record<string, any>),
        ...(esRequestTour as Record<string, any>),
        ...(esRequestInfo as Record<string, any>),
        ...(esLocations as Record<string, string>),
        ...(esBookingLegacy as Record<string, any>),
        ...(esPrivacy as Record<string, any>),
        ...(esSitemap as Record<string, string>),
        ...(esLogin as Record<any, any>),
      },
    },
    intlESCache,
  ) as IntlShape;

  const intls = {
    en: intlEN,
    it: intlIT,
    fr: intlFR,
    es: intlES,
  };

  const intl = intls[locale ?? 'en'];

  return { intlEN, intlFR, intlIT, intlES, intl, intls };
};
